import React from 'react'
import Button from '@mui/material/Button'
import EvilSpan from './EvilSpan'
import { navigate } from 'gatsby'
import { trackEvent } from '../../utils/ArtboxoneTracking'

export interface CTAButtonProps {
  context: string
  title: string
  link: string
  testId?: string
}

export default function CTAButton(props: CTAButtonProps) {
  const handleClick = (ev: any) => {
    ev.preventDefault()

    trackEvent({
      category: props.context,
      action: 'Button_' + props.title,
      label: props.link,
    })

    navigate(props.link)
  }

  let test = props.context + '_' + props.title

  if (typeof props.testId !== 'undefined') {
    test = props.context + '_' + props.testId
  }

  return (
    <Button
      role="link"
      data-test={test}
      className="cta_button"
      variant="contained"
      color="primary"
      onClick={handleClick}
      href={props.link}
    >
      <EvilSpan content={props.title} />
    </Button>
  )
}
