import React from 'react'
import { TileElement } from '../../../types'
import { Grid, Paper } from '@mui/material'
import { useArtboxONEStyles } from '../../../utils/Style'
import CTAButton from '../CTAButton'
import FluidImage from '../../product/FluidImage'

export interface TileProps {
  element: TileElement
}

export default function Tile(props: TileProps) {
  const classes = useArtboxONEStyles()
  let element = props.element

  return (
    <Grid item xs={12} sm={4} md={3}>
      <Paper className={classes.paper}>
        <h3>{element.title}</h3>
        {element.image_url !== '' && (
          <FluidImage
            title={element.title}
            imageUrl={element.image_url}
            link={element.cta_link}
          />
        )}
        <CTAButton
          context="tile"
          title={element.cta_title}
          link={element.cta_link}
        />
      </Paper>
    </Grid>
  )
}
