import React from 'react'
import Layout from '../components/layout/Layout'
import SEO from '../components/generic/Seo'
import Teaser from '../components/artboxone/Teaser'
import CTAButton from '../components/generic/CTAButton'
import HHAllePage from './hh_alle'
import Helmet from 'react-helmet'
import CmsBlock from '../components/cms/CmsBlock'
import Breadcrumb from '../components/artboxone/Breadcrumb'
import { AlternateUrl, BreadcrumbLayer, PageInformation, TileElement } from '../types'
import TileGrid from '../components/generic/tiles/TileGrid'
import { formatPrice } from '../utils/utils'
import { renderImageUrlPixumImageId } from '../lib/imageurl_api'
import { buildAlternativeUrlsForLps } from '../utils/UrlUtils'
import {env} from '../../environment'

const HHManufacturerPage = (options: any) => {
  const lps = options.pageContext.lps
  let title = 'Design-Handyhüllen für dein %t-Smartphone'
  let description =
    'Handverlesene Designs als Handyhülle: Mach Dein %t-Mobiltelefon zu deinem neuen Lieblings-Accessoire! Individueller Look und bester Schutz perfekt kombiniert.'

  let seoTitle = 'Design-Handyhüllen für dein %t-Smartphone | artboxONE'
  let seoDescription =
    'Handverlesene Designs als Handyhülle: Mach Dein %t-Mobiltelefon zu deinem neuen Lieblings-Accessoire! Individueller Look und bester Schutz perfekt kombiniert.'

  let manufacturer = ''
  let url_manufacturer = ''

  lps.map((lp: any) => {
    if (manufacturer == '') {
      manufacturer = lp.manufacturer
      url_manufacturer = lp.url_manufacturer
    }
  })

  seoTitle = seoTitle.replace('%t', manufacturer)
  seoDescription = seoDescription.replace('%t', manufacturer)

  title = title.replace('%t', manufacturer)
  description = description.replace('%t', manufacturer)

  const breadcrumbLayers: BreadcrumbLayer[] = []

  breadcrumbLayers.push({
    url: '/geschenke',
    name: 'Geschenke',
  })

  breadcrumbLayers.push({
    url: '/geschenke/handyhuellen',
    name: 'Handyhüllen',
  })

  breadcrumbLayers.push({
    url: '/geschenke/handyhuellen/' + url_manufacturer,
    name: manufacturer,
  })

  const tileElements: TileElement[] = []

  lps.map((lp: any) =>
    tileElements.push({
      image_url: renderImageUrlPixumImageId({
        template: lp.image,
        pixumImageId: 903409241,
      }),
      cta_link:
        '/geschenke/handyhuellen/' + lp.url_manufacturer + '/' + lp.url_model,
      cta_title: 'Ab ' + formatPrice(lp.price) + '',
      title: lp.model,
    }),
  )

  const pageInformation: PageInformation = { type: 'hh', material: 'cases' }


  const canonicals: any = []
  canonicals.push(
    <link
      href={
        env.getBaseUrl() +
        options.path
      }
      key="canonical-lp"
      rel="canonical"
    />,
  )

  buildAlternativeUrlsForLps('/', {de: options.path, en: '', ch: options.path}).map((alternateUrl: AlternateUrl) => {
    canonicals.push(
      <link
        href={alternateUrl.url}
        hrefLang={alternateUrl.locale}
        key={'sitemap-' + alternateUrl.url}
        rel="sitemap"
      />,
    )
  })


  return (
    <>
      <Helmet>
        <meta content="index,follow" name="robots" />
        {canonicals.map((canonical: any) => canonical)}
      </Helmet>
      <Layout pageInformation={pageInformation}>
        <SEO description={seoDescription} title={seoTitle} />
        <Breadcrumb layers={breadcrumbLayers} />
        <div className="container">
          <div className="row">
            <div className="col">
              <Teaser body={description} head={title} />

              <h2>Handymodelle von {manufacturer}</h2>
              <p>
                Für {manufacturer}-Geräte bieten wir Handyhüllen der folgenden
                Modelle an:
              </p>
              <TileGrid elements={tileElements} />

              <h2>Handyhüllen anderer Hersteller</h2>
              <p>
                Schaue auf der Handyhüllen-Übersicht, ob wir Hüllen für deinen
                Handyhersteller anbieten.
              </p>
              <p>
                <CTAButton
                  context="hh_lp_manufacturer"
                  link="/geschenke/handyhuellen"
                  title="Zurück zu allen Handyhüllen"
                />
              </p>
              <p>&nbsp;</p>

              <HHAllePage />
              <p>&nbsp;</p>

              <CmsBlock blockId={'case_' + url_manufacturer} />
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default HHManufacturerPage
